import { Link } from 'gatsby';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import * as styles from './Button.module.scss';

export const ButtonType = {
    LINK: 'LINK',
    SUBMIT: 'SUBMIT',
};

export const ButtonStyle = {
    BLUE: 'BLUE',
    WHITE: 'WHITE',
};

const Button = ({
    type, text, link = '', className, style = ButtonStyle.BLUE, children
}) => {
    const { formatMessage } = useIntl();

    switch (type) {
    case ButtonType.LINK:
        return (
            <Link
                to={link}
                className={
                    classNames(styles.button, styles[style], className)
                }
            >
                {formatMessage(text)}
            </Link>
        );
    case ButtonType.SUBMIT:
    default:
        return (
            <button
                type="submit"
                className={classNames(
                    styles.button, styles[style], className,
                )}
            >
                {text ? (
                    formatMessage(text)
                ) : (
                    children
                )}
            </button>
        );
    }
};

export default Button;
