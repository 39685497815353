/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { Link as RouterLink } from '@reach/router';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import axios from 'axios';
import useWindowWidth from '../../hooks/useWindowWidth';
import Button, { ButtonStyle, ButtonType } from '../Button/Button';
import logoImgPath from './img/logo.svg';
import whiteLogoImgPath from './img/white_logo.svg';
import * as styles from './Header.module.scss';
import { getServiceName } from '../../helpers/helpers';
// import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

const messages = defineMessages({
    title: {
        id: 'heroSection.title',
        defaultMessage: 'Social media services you can trust',
    },
    whyUs: {
        id: 'header.whyUs',
        defaultMessage: 'Why Us',
    },
    pricing: {
        id: 'header.pricing',
        defaultMessage: 'Pricing',
    },
    faq: {
        id: 'header.faq',
        defaultMessage: 'FAQ',
    },
    contactUs: {
        id: 'header.contactUs',
        defaultMessage: 'Contact Us',
    },
    trackOrder: {
        id: 'header.trackOrder',
        defaultMessage: 'Track Order',
    },
    heroText: {
        id: 'header.heroText',
        defaultMessage: 'Social channels may propose you some huge advantages as millions of people are gathering around at every moment. And we are here to help you to conquer their hearts.',
    },
});

const Header = ({ isIndex = false }) => {
    const { formatMessage } = useIntl();
    const [isOpen, setIsOpen] = useState(false);
    const [list, setList] = useState([]);
    const isMobile = useWindowWidth() < 992;

    const isHeroSectionVisible = isIndex;

    const getList = () => {
        axios.get(`${process.env.GATSBY_API_URL}/api/service/favorite`).then(({ data }) => setList(data)).catch((e) => console.log(e));
    };

    const menuTrigger = () => {
        if (isMobile) setIsOpen(false);
    };

    useEffect(() => {
        if (!isMobile) setIsOpen(false);
    }, [isMobile]);

    useEffect(() => {
        if (isIndex) getList();
    }, [isIndex]);

    return (
        <>
            <header className={classNames({ [styles.open]: isOpen })}>
                <div className={styles.header}>
                    <Link to="/">
                        {isOpen ? (
                            <img src={whiteLogoImgPath} alt="Logo" />
                        ) : (
                            <img src={logoImgPath} alt="Logo" />
                        )}
                    </Link>
                    <nav className={classNames(styles.nav, { [styles.open]: isOpen })}>
                        <ul className={classNames(styles.list, {
                            [styles.open]: isOpen,
                            [styles.white]: isHeroSectionVisible,
                        })}
                        >
                            <li onClick={() => menuTrigger()}>
                                <Link to="/#why-us">{formatMessage(messages.whyUs)}</Link>
                            </li>
                            <li onClick={() => menuTrigger()}>
                                <Link to="/#pricing">{formatMessage(messages.pricing)}</Link>
                            </li>
                            <li onClick={() => menuTrigger()}>
                                <Link to="/#faq">{formatMessage(messages.faq)}</Link>
                            </li>
                            <li onClick={() => menuTrigger()}>
                                <a href="mailto:support@cheap-social.com">{formatMessage(messages.contactUs)}</a>
                            </li>
                            <li>
                                <Button type={ButtonType.LINK} link="/track-order" style={isHeroSectionVisible ? ButtonStyle.WHITE : ButtonStyle.BLUE} text={messages.trackOrder} />
                            </li>
                            {/* <li>
                                <LanguageSwitcher isWhite={isHeroSectionVisible} />
                            </li> */}
                        </ul>
                    </nav>
                    <ul
                        className={classNames(
                            styles.menuTrigger, { [styles.open]: isOpen },
                        )}
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <li />
                        <li />
                        <li />
                    </ul>
                </div>
            </header>
            {isHeroSectionVisible && (
                <section className={styles.hero}>
                    <div className={styles.grid}>
                        <div className={styles.heroData}>
                            <h1 className={styles.title}>{formatMessage(messages.title)}</h1>
                            <p>
                                {formatMessage(messages.heroText)}
                            </p>
                            <a href="#pricing" className={styles.button}>
                                <FormattedMessage id="heroSection.takeMeToPricing" defaultMessage="Take me to pricing" />
                            </a>
                            <div className={styles.box}>
                                <h4><FormattedMessage id="heroSection.mostPopular" defaultMessage="Most popular services:" /></h4>
                                {list.length > 0 && list.map(({ index, name, category }) => (
                                    <>
                                        {isMobile ? (
                                            <RouterLink to={`/buy/${category.toLowerCase()}/${name}`} key={`${name}-${index}`}>{`${getServiceName(category)} ${name}`}</RouterLink>
                                        ) : (
                                            <Link to={`/buy/${category.toLowerCase()}/${name}`} key={`${name}-${index}`}>{`${getServiceName(category)} ${name}`}</Link>
                                        )}
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

export default Header;
