/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import { defineMessages, FormattedMessage } from 'react-intl';
import { useContext } from 'react';
import { Context } from '../../../store/Provider';
import exitImgPath from './img/exit_img.svg';
import * as styles from './ExitModal.module.scss';

const messages = defineMessages({
    title: {
        id: 'exitModal.title',
        defaultMessage: 'Welcome',
    },
    post: {
        id: 'exitModal.post',
        defaultMessage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
});

const ExitModal = () => {
    const { dispatch } = useContext(Context);
    return (
        <div className={styles.container}>
            <span
                className={styles.close}
                onClick={() => dispatch({ type: 'TOGGLE_MODAL' })}
            />
            <span className={styles.imgContainer}>
                <img src={exitImgPath} alt="Exit Intent Images" />
            </span>
            <div>
                <h3>
                    <FormattedMessage {...messages.title} />
                </h3>
                <p>
                    <FormattedMessage {...messages.post} />
                </p>
                <p>
                    <FormattedMessage
                        id="exitModal.useCode"
                        defaultMessage="Use code <b>FIRSTTIME</b>"
                        values={{ b: (text) => <b>{text}</b> }}
                    />
                </p>
            </div>
        </div>
    );
};

export default ExitModal;
