import { useEffect, useContext, useRef } from 'react';
import exitIntent from 'exit-intent';
import { IntlProvider } from 'react-intl';
import 'font-awesome/css/font-awesome.css';
import { Context } from '../store/Provider';
import ExitModal from './Modal/ExitModal/ExitModal';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import ModalWrapper from './Modal/ModalWrapper';
import en from '../translations/en.json';
import { getLivechatScript, getFacebookPixel } from '../helpers/helpers';
import useWindowWidth from '../hooks/useWindowWidth';

const Layout = ({ children, isIndex = false }) => {
    const { dispatch } = useContext(Context);
    const timer = useRef(null);
    const isMobile = useWindowWidth() < 992;

    useEffect(() => {
        exitIntent({
            threshold: 10,
            maxDisplays: 1,
            eventThrottle: 10,
            onExitIntent: () => (isIndex && typeof window !== typeof undefined && window.location.pathname === '/'
                ? dispatch({
                    type: 'TOGGLE_MODAL',
                    payload: <ExitModal />,
					  })
                : null),
            storageName: 'exit-visited',
        });
    }, []);

    useEffect(() => {
        if (
            !timer.current
			&& isMobile
			&& isIndex
			&& typeof window !== typeof undefined
			&& window.location.pathname === '/'
        ) {
            timer.current = window.setTimeout(
                () => dispatch({
                    type: 'TOGGLE_MODAL',
                    payload: <ExitModal />,
                }),
                4000,
            );
        }
        return () => clearTimeout(timer.current);
    }, []);

    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-112916642-1';
        script.async = true;

        document.body.appendChild(script);
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.async = true;
        script.innerHTML = `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      
        gtag('config', 'UA-112916642-1');`;
        document.body.appendChild(script);
    }, []);

    useEffect(() => {
        getLivechatScript();
        getFacebookPixel();
    }, []);

    return (
        <IntlProvider defaultLocale="en" locale="en" messages={en}>
            <ModalWrapper />
            <Header isIndex={isIndex} />
            <main className="content">{children}</main>
            <Footer />
        </IntlProvider>
    );
};

export default Layout;
