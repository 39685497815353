import { useContext } from 'react';
import ReactModal from 'react-modal';
import { Context } from '../../store/Provider';
import * as styles from './ModalWrapper.module.scss';

const ModalWrapper = () => {
    const { dispatch, state } = useContext(Context);
    const content = state?.TOGGLE_MODAL?.content;
    const isOpen = content;

    const closeModal = () => dispatch({
        type: 'TOGGLE_MODAL',
        payload: null,
    });

    if (!isOpen && !content) return null;
    return (
        <ReactModal
            isOpen={content && true}
            className={styles.modal}
            overlayClassName={styles.overlay}
            ariaHideApp={false}
            onRequestClose={() => closeModal()}
        >
            {content}
        </ReactModal>
    );
};

export default ModalWrapper;
