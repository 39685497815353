// extracted by mini-css-extract-plugin
export var box = "Header-module--box--8jIQd";
export var button = "Header-module--button--Ikb06";
export var grid = "Header-module--grid--xTv1T";
export var header = "Header-module--header--NXzL8";
export var heroData = "Header-module--heroData--EdNd1";
export var list = "Header-module--list--Ec-gW";
export var menuTrigger = "Header-module--menuTrigger--JLIjO";
export var nav = "Header-module--nav--e5nQ0";
export var open = "Header-module--open--fackP";
export var title = "Header-module--title---8lmG";
export var white = "Header-module--white--mfix-";