export const getServiceName = (name) => {
    switch (name.toUpperCase()) {
    case 'TIKTOK':
        return 'TikTok';
    case 'PINTEREST':
        return 'Pinterest';
    case 'SPOTIFY':
        return 'Spotify';
    case 'TUMBLR':
        return 'Tumblr';
    case 'TWITCH':
        return 'Twitch';
    case 'LINKEDIN':
        return 'LinkedIn';
    case 'YOUTUBE':
        return 'Youtube';
    case 'FACEBOOK':
        return 'Facebook';
    case 'TWITTER':
        return 'Twitter';
    case 'INSTAGRAM':
        return 'Instagram';
    default:
        return null;
    }
};

export const getLivechatScript = () => {
    setTimeout(() => {
        (function () {
            const lc = document.createElement('script');
            lc.type = 'text/javascript';
            lc.async = true;
            lc.src = `${
                document.location.protocol === 'https:' ? 'https://' : 'http://'
            }embed.tawk.to/601c17dec31c9117cb75dd34/1etmrqfhj`;
            const s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(lc, s);
        }());
    }, 2000);
};

export const getGoogleAnalyticsScript = (
    userReferralCode,
    utmTracking,
    googleAnalyticsId,
    callback,
) => {
    /* eslint-disable */
	(function (i, s, o, g, r, a, m) {
		i.GoogleAnalyticsObject = r;
		(i[r] =
			i[r] ||
			function () {
				(i[r].q = i[r].q || []).push(arguments);
			}),
			(i[r].l = 1 * new Date());
		(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
		a.async = 1;
		a.src = g;
		m.parentNode.insertBefore(a, m);
	})(
		window,
		document,
		'script',
		'https://www.googletagmanager.com/gtag/js?id=UA-112916642-1',
		'ga',
	);
	/* eslint-enable */
    if (typeof callback === 'function') callback();
};

export const getFacebookPixel = () => {
    /* eslint-disable */
	!(function (f, b, e, v, n, t, s) {
		if (f.fbq) return;
		n = f.fbq = function () {
			n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
		};
		if (!f._fbq) f._fbq = n;
		n.push = n;
		n.loaded = !0;
		n.version = '2.0';
		n.queue = [];
		t = b.createElement(e);
		t.async = !0;
		t.src = v;
		s = b.getElementsByTagName(e)[0];
		s.parentNode.insertBefore(t, s);
	})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
	/* eslint-enable */
    window.fbq('consent', 'grant');
    window.fbq('init', '2147446402174621');
    window.fbq('track', 'PageView');
};
