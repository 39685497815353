import { Link } from 'gatsby';
import { Link as RouterLink } from '@reach/router';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useWindowWidth from '../../hooks/useWindowWidth';
import logoImgPath from './img/logo.svg';
import * as styles from './Footer.module.scss';

const Footer = () => {
    const [list, setList] = useState([]);
    const isMobile = useWindowWidth() < 992;

    const getLinks = () => {
        axios.get(`${process.env.GATSBY_API_URL}/api/link`).then(({ data }) => {
            setList(data);
        }).catch((e) => {
            console.log(e);
        });
    };

    useEffect(() => {
        getLinks();
    }, []);

    return (
        <div className={styles.background}>
            <div className={styles.footer}>
                <Link to="/" className={styles.logo}>
                    <img src={logoImgPath} alt="Logo" />
                </Link>
                <ul className={styles.list}>
                    {list.length > 0 && list.map(({ title, url }) => (
                        <li key={url}>
                            {isMobile ? (
                                <RouterLink to={url}>{title}</RouterLink>
                            ) : (
                                <Link to={url}>{title}</Link>
                            )}
                        </li>
                    ))}
                </ul>
                <ul>
                    <li>
                        <Link to="/terms-of-service">
                            <FormattedMessage id="footer.terms" defaultMessage="Terms of service" />
                        </Link>
                    </li>
                    <li>
                        <Link to="/privacy-policy">
                            <FormattedMessage id="footer.privacyPolicy" defaultMessage="Privacy policy" />
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;
